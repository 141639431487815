<template>
  <div class="test-register">
    <a-row type="flex" justify="center">
      <a-col :span="23" :lg="16">
        <a-row
          style="
            background-color: #ffffff;
            box-shadow: 0px 0px 17px -8px rgba(14, 155, 210, 20);
            border-radius: 8px;
          "
          :style="{ padding: fullWidth > 992 ? '20px 30px' : '20px 10px' }"
        >
          <a-col :span="24">
            <div style="display: flex; align-items: center">
              <el-page-header
                style="
                  width: 100%;
                  height: 70px;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  margin-left: 10px;
                "
                @back="back"
                content="填写比赛信息"
              >
              </el-page-header>
            </div>
          </a-col>
          <div class="form-content">
            <!-- 报告人 -->
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="8" :lg="4">
                <div class="form-title-font">参赛人身份</div>
              </a-col>
              <a-col :span="16" :lg="20">
                <div style="display: flex; justify-content: flex-end; cursor: pointer">
                  <el-dropdown trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                      {{ identity }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        :command="item"
                        v-for="(item, index) in IdentityList"
                        :key="index"
                        >{{ item }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </a-col>
            </a-row>
            <!-- 参赛人 -->
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="12" :lg="16">
                <div class="form-title-font">参赛人</div>
              </a-col>
              <a-col :span="12" :lg="8">
                <div style="display: flex; justify-content: flex-end">
                  <a-input
                    style="border: none; text-align: right"
                    class="form-value-font"
                    v-model="userName"
                    placeholder="请输入"
                    @blur="testUsername()"
                  ></a-input>
                </div>
              </a-col>
            </a-row>
            <!-- 手机号 -->
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="8" :lg="16">
                <div class="form-title-font">手机号</div>
              </a-col>
              <a-col :span="16" :lg="8">
                <div style="display: flex; justify-content: flex-end">
                  <a-input
                    class="form-value-font tel"
                    style="border: none; text-align: right"
                    v-model="mobile"
                    placeholder="请输入"
                    @blur="testTel()"
                    type="number"
                  ></a-input>
                </div>
              </a-col>
            </a-row>
            <!-- 身份证号 -->
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="8" :lg="16">
                <div class="form-title-font">身份证号</div>
              </a-col>
              <a-col :span="16" :lg="8">
                <div style="display: flex; justify-content: flex-end">
                  <a-input
                    class="form-value-font tel"
                    style="border: none; text-align: right"
                    v-model="idCardNo"
                    placeholder="请输入"
                    @blur="idCard()"
                  ></a-input>
                </div>
              </a-col>
            </a-row>
            <!-- 所属地区 -->
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="8" :lg="8">
                <div class="form-title-font">所属地区</div>
              </a-col>
              <a-col :span="16" :lg="16">
                <div class="block" style="text-align: right; border: none">
                  <el-cascader
                    v-model="cityValue"
                    :options="options"
                    :props="{ expandTrigger: 'hover' }"
                    @change="handleChange"
                    @blur="testCity()"
                  ></el-cascader>
                </div>
              </a-col>
            </a-row>
            <!-- 学校 -->
            <a-row
              :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'"
              v-if="identity == '学生' || identity == '老师'"
            >
              <a-col :span="8" :lg="8">
                <div class="form-title-font">
                  {{ "学校" }}
                </div>
              </a-col>
              <a-col :span="16" :lg="16">
                <div class="block" style="text-align: right">
                  <el-cascader
                    placeholder="请选择"
                    v-model="schoolValue"
                    :options="schoolListOptions"
                    :disabled="flag"
                    :props="props"
                    @change="handleSchoolChange"
                    @blur="testSchool()"
                    filterable
                  ></el-cascader>
                </div>
              </a-col>
            </a-row>
            <!-- 班级 -->
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="8" :lg="16">
                <div class="form-title-font">
                  {{ identity == "学生" ? "班级" : identity == "老师" ? "学科" : "单位" }}
                </div>
              </a-col>
              <a-col :span="16" :lg="8" v-if="identity == '学生'">
                <div style="display: flex; justify-content: flex-end">
                  <a-input
                    class="form-value-font"
                    style="border: none; text-align: right"
                    v-model="classInfo"
                    placeholder="请输入"
                    @blur="testClass()"
                  ></a-input>
                </div>
              </a-col>
              <!-- 学科 -->
              <a-col :span="16" :lg="8" v-if="identity == '老师'">
                <div style="display: flex; justify-content: flex-end">
                  <a-input
                    class="form-value-font"
                    style="border: none; text-align: right"
                    v-model="classCourse"
                    placeholder="请输入"
                    @blur="testClass()"
                  ></a-input>
                </div>
              </a-col>
              <!-- 单位 -->
              <a-col :span="16" :lg="8" v-if="identity == '其他人士'">
                <div style="display: flex; justify-content: flex-end">
                  <a-input
                    class="form-value-font"
                    style="border: none; text-align: right"
                    v-model="unit"
                    placeholder="请输入"
                    @blur="testClass()"
                  ></a-input>
                </div>
              </a-col>
            </a-row>
            <!-- 辅导老师 -->
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="8" :lg="16">
                <div class="form-title-font">
                  {{
                    identity == "学生" ? "辅导老师" : identity == "老师" ? "职务" : "职务"
                  }}
                </div>
              </a-col>
              <!-- 辅导老师 -->
              <a-col :span="16" :lg="8" v-if="identity == '学生'">
                <div style="display: flex; justify-content: flex-end">
                  <a-input
                    class="form-value-font"
                    style="border: none; text-align: right"
                    v-model="teacher"
                    placeholder="请输入"
                  ></a-input>
                </div>
              </a-col>
              <!-- 职务 -->
              <a-col
                :span="16"
                :lg="8"
                v-if="identity == '老师' || identity == '其他人士'"
              >
                <div style="display: flex; justify-content: flex-end">
                  <a-input
                    class="form-value-font"
                    style="border: none; text-align: right"
                    v-model="teacherDuty"
                    placeholder="请输入"
                  ></a-input>
                </div>
              </a-col>
            </a-row>
            <!-- 提交信息 -->
            <div style="width: 100%; margin: 30px auto 0 auto; padding-bottom: 30px">
              <a-button
                style="
                  border-radius: 25px;
                  background: linear-gradient(90deg, #51d2be, #0090d7);
                  font-size: 15px;
                  color: #fefefe;
                  font-weight: bold;
                  height: 50px;
                  width: 340px;
                  border: none;
                "
                @click="submitTest()"
                >提交
              </a-button>
            </div>
          </div>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      identity: "学生", // 参赛人身份
      userName: "", // 参赛人
      mobile: "", // 手机号
      idCardNo: "", // 身份证号
      flag: true,
      toplicId: "",
      IdentityList: [],

      // 所属地区
      cityCodeList: [],
      cityValue: [],
      options: [
        {
          value: "贵州省",
          label: "贵州省",
          children: [
            // {
            //   value: "毕节市",
            //   label: "毕节市",
            //   children: [],
            // },
          ],
        },
      ],
      // 学校
      schoolValue: [],
      schoolListOptions: [
        {
          value: "学前班",
          label: "学前班",
          children: [],
        },
        {
          value: "幼儿园",
          label: "幼儿园",
          children: [],
        },
        {
          value: "小学",
          label: "小学",
          children: [],
        },
        {
          value: "中学",
          label: "中学",
          children: [],
        },
        {
          value: "职业学院",
          label: "职业学院",
          children: [],
        },
        {
          value: "教学点",
          label: "教学点",
          children: [],
        },
        {
          value: "其他",
          label: "其他",
          children: [],
        },
      ],
      cityCode: "", // 所在城市编码
      cityName: "",
      areaId: "",
      schoolId: "", // 学校Id
      totalSchoolList: [], // 学校总数据
      preschool: [], // 学前班
      kindergarten: [], // 幼儿园
      primarySchool: [], // 小学
      MiddleSchool: [], // 中学
      vocationalCollege: [], //职业学院
      teachingPoint: [], // 教学点
      otherSchool: [], // 其他学校

      // 班级
      classInfo: "",
      unit: "",
      teacherDuty: "",
      classCourse: "", // 学科

      // 辅导老师
      teacher: "",
      identityNo: 1,

      classRoomId: "",

      props: {
        expandTrigger: "hover",
      },

      // 答题时间
      testTime: 0,
    };
  },
  created() {
    this.toplicId = this.$route.query.specialId;
    this.classRoomId = this.$route.query.classRoomId;
    console.log("房间id", this.classRoomId);
    console.log("id", this.toplicId);
    this.getIndentify();
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getCityCode();
    this.getTestTotalTime();
  },
  computed: {
    ...mapState(["fullWidth"]),
  },
  methods: {
    handleCommand(command) {
      this.userName = "";
      this.mobile = "";
      this.idCardNo = "";
      this.cityValue = [];
      this.schoolValue = [];
      this.flag = true;
      this.identity = command;
      this.identityNo = 0;
      let that = this;
      this.identityNo = this.IdentityList.findIndex(function (value, index, arr) {
        return value == that.identity;
      });
      console.log(command);
      console.log(this.identityNo);
    },
    // 所属地区方法占位
    handleChange(value) {
      console.log("value", value);
      this.flag = false;
      this.cityName = value[2];
      this.cityCodeList.forEach((v, i) => {
        if (v.name === value[2]) {
          console.log(v.name);
          this.cityCode = v.cityCode;
          this.areaId = v.id;
          console.log("所在城市编码", this.cityCode);
          // 获取对应区县学校详情
          this.$HTTP
            .httpToken({
              url: "/shouwei/school/list",
              method: "post",
              data: {
                areaId: this.areaId,
                cityCode: this.cityCode,
              },
            })
            .then((res) => {
              console.log("学校总数", res.data);
              this.totalSchoolList = res.data;

              this.preschool = [];
              this.kindergarten = [];
              this.primarySchool = [];
              this.MiddleSchool = [];
              this.vocationalCollege = [];
              this.teachingPoint = [];
              this.otherSchool = [];

              this.totalSchoolList.forEach((v, i) => {
                if (v.stage === 1) {
                  // 学前班
                  let preschool = [];
                  preschool.push(v);
                  let temp = preschool.map((v) => {
                    return {
                      label: v.schoolName,
                      value: v.schoolName,
                    };
                  });

                  this.preschool.push(...temp);
                } else if (v.stage === 2) {
                  // 幼儿园
                  let kindergarten = [];
                  kindergarten.push(v);
                  let temp = kindergarten.map((v) => {
                    return {
                      label: v.schoolName,
                      value: v.schoolName,
                    };
                  });
                  this.kindergarten.push(...temp);
                } else if (v.stage === 3) {
                  // 小学
                  let primarySchool = [];
                  primarySchool.push(v);
                  let temp = primarySchool.map((v) => {
                    return {
                      label: v.schoolName,
                      value: v.schoolName,
                    };
                  });
                  this.primarySchool.push(...temp);
                } else if (v.stage === 4) {
                  // 中学
                  let MiddleSchool = [];
                  MiddleSchool.push(v);
                  let temp = MiddleSchool.map((v) => {
                    return {
                      label: v.schoolName,
                      value: v.schoolName,
                    };
                  });
                  this.MiddleSchool.push(...temp);
                } else if (v.stage === 5) {
                  // 职业学院
                  let vocationalCollege = [];
                  vocationalCollege.push(v);
                  let temp = vocationalCollege.map((v) => {
                    return {
                      label: v.schoolName,
                      value: v.schoolName,
                    };
                  });
                  this.vocationalCollege.push(...temp);
                } else if (v.stage === 6) {
                  // 教学点
                  let teachingPoint = [];
                  teachingPoint.push(v);
                  let temp = teachingPoint.map((v) => {
                    return {
                      label: v.schoolName,
                      value: v.schoolName,
                    };
                  });
                  this.teachingPoint.push(...temp);
                } else {
                  // 其他学校
                  let otherSchool = [];
                  otherSchool.push(v);
                  let temp = otherSchool.map((v) => {
                    return {
                      label: v.schoolName,
                      value: v.schoolName,
                    };
                  });
                  this.otherSchool.push(...temp);
                }
              });
              this.getSchoolName();
            })
            .catch((e) => {});
        }
      });
    },
    // 选择学校方法占位
    handleSchoolChange(schoolName) {
      // 获取学校列表
      this.schoolListOptions.forEach((v, i) => {
        if (schoolName[0] == v.value) {
          v.children.forEach((v, i) => {
            if (schoolName[1] == v.value) {
              this.schoolId = v.schoolId;
            }
          });
        }
      });
    },
    getSchoolName() {
      // 将数据格式处理好的学校数据放入data中
      this.schoolListOptions.forEach((v, i) => {
        if (v.value === "学前班") {
          v.children = this.preschool;
        } else if (v.value === "幼儿园") {
          v.children = this.kindergarten;
        } else if (v.value === "小学") {
          v.children = this.primarySchool;
        } else if (v.value === "中学") {
          v.children = this.MiddleSchool;
        } else if (v.value === "职业学院") {
          v.children = this.vocationalCollege;
        } else if (v.value === "教学点") {
          v.children = this.teachingPoint;
        } else {
          // 其他
          v.children = this.otherSchool;
        }
      });
    },
    // 获取区县编码
    getCityCode() {
      this.$HTTP
        .httpToken({
          url: "/shouwei/area/getAreaOpenStatusDataList",
          method: "post",
          data: {},
        })
        .then((res) => {
          console.log("区县编码", res);
          if (res.code == 0 && res.data) {
            // 获取贵州省所有的市
            this.options[0].children = res.data[0].children.map((v, i) => {
              return {
                label: v.name,
                value: v.name,
                children: v.children.map((v1) => {
                  return {
                    label: v1.name,
                    value: v1.name,
                  };
                }),
              };
            });

            this.cityCodeList = res.data[0].children.map((v) => {
              return v.children;
            });

            let arr = [];

            this.cityCodeList.forEach((v) => {
              arr.push(...v);
            });
            this.cityCodeList = arr;
            console.log("this.cityCodeList :>> ", this.cityCodeList);
            // this.options[0].children[0].children = this.cityCodeList.map(
            //   (v) => {
            //     return {
            //       value: v.name,
            //       label: v.name,
            //     };
            //   }
            // );
            console.log("区", cityCodeArr);
            console.log(res.data);
            // this.areaId = res.data[0].children[0].id;
          }
        })
        .catch((e) => {});
    },
    // 验证参赛人
    testUsername() {
      if (!this.userName) {
        // this.$message.error("请输入姓名");
        return false;
      } else {
        let reg = /^[\u4e00-\u9fa5]{0,}$/;
        if (!reg.test(this.userName)) {
          this.$message.error("姓名格式错误");
          return false;
        }
      }
      return true;
    },

    // 验证身份证号
    idCard() {
      if (!this.idCardNo) {
        // this.$message.error("请输入身份证号");
        return false;
      } else {
        let reg = /^\d{15}|\d{18}$/;
        if (!reg.test(this.idCardNo)) {
          this.$message.error("请输入正确的身份证号");
          return false;
        }
      }
      return true;
    },
    // 验证电话号码
    testTel() {
      if (!this.mobile) {
        // this.$message.error("请输入手机号");
        return false;
      } else {
        let reg = /^(13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/;
        if (!reg.test(this.mobile)) {
          this.$message.error("请输入正确的手机号");
          return false;
        }
      }
      return true;
    },

    // 验证地区
    testCity() {
      if (!this.cityValue) {
        // this.$message.error("请选择地区");
        return false;
      } else {
        return true;
      }
    },

    // 验证学校
    testSchool() {
      if (!this.schoolValue) {
        // this.$message.error("请选择学校");
        return false;
      } else {
        return true;
      }
    },

    // 验证老师
    // testTeacher() {
    //   if (!this.teacher) {
    //     // this.$message.error("请填写辅导老师信息");
    //     return false;
    //   } else {
    //     return true;
    //   }
    // },

    // 验证班级
    testClass() {
      if (!this.classInfo || !this.classCourse || !this.unit) {
        // this.$message.error("请填写班级信息");
        return false;
      } else {
        return true;
      }
    },

    // 提交参赛信息
    submitTest() {
      let flag = [
        this.idCard(),
        this.testTel(),
        this.testCity(),
        this.testSchool(),
        // this.testClass(),
        this.testUsername(),
      ].every((v) => {
        return v;
      });

      if (!flag) {
        this.$message.error("请将信息填写完整");
      } else {
        this.$HTTP
          .httpToken({
            url: "/wst/testPaper/participating",
            method: "post",
            data: {
              topicId: this.toplicId,
              cityCode: this.cityCode,
              userName: this.userName,
              mobile: this.mobile,
              idCardNo: this.idCardNo,
              cityName: this.cityName,
              schoolId: this.schoolId,
              schoolName: this.schoolName,
              classInfo: this.classInfo,
              identity: this.identityNo + 1,
              teacherDuty: this.teacherDuty,
              unit: this.unit,
              teacher: this.teacher,
            },
          })
          .then((res) => {
            console.log(res);
            if (res.code == 0 && res.data) {
              this.$message.success("填写信息成功");

              // 将用户的userFlagId保存在store中
              this.$store.dispatch("set_userFlagId", res.data.userFlagId);

              // 跳转到答题页面
              this.$router.push({
                path: "/specialclass/testpaper",
                query: {
                  topicId: this.toplicId,
                  testTime: this.testTime,
                  app_no: this.$route.query.app_no,
                },
              });
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch((e) => {
            this.$message.error(e);
          });
      }
    },
    back() {
      this.$router.back();
    },

    // 获取答题时间
    getTestTotalTime() {
      this.$HTTP
        .httpToken({
          url: `/wst/testPaper/topicInfo/${this.toplicId}`,
          method: "post",
          data: {
            topicId: this.toplicId,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0 && res.data) {
            console.log("答题时间", res.data.answerTime);
            this.testTime = res.data.answerTime;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((e) => {
          this.$message.error(e);
        });
    },
    // 获取身份
    getIndentify() {
      let that = this;
      this.$HTTP
        .httpToken({
          url: "/wst/testPaper/getMatchTopicParticipatingUsersIdentity",
          method: "post",
          data: {
            ["topicId"]: this.toplicId, //[]里面可以装表达式
          },
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0 && res.data) {
            this.IdentityList = res.data;
            if (this.IdentityList.length != 0) {
              that.identity = that.IdentityList.at(-1);
              that.identityNo = that.IdentityList.findIndex(function (value, index, arr) {
                return value == that.identity;
              });

              console.log("身份 :>> ", that.identity, that.identityNo);
            }
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((e) => {
          this.$message.error(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 80%;
  margin: -30px auto 0 auto;
  position: relative;
  z-index: 10;
}
.imagesBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .details-img {
    height: 160px;
    width: 160px;
    margin-right: 20px;
  }
}

// 详情卡片样式
.form-header {
  background-color: #e3e3e3;
  font-size: 18px;
  color: #717171;
  font-weight: bold;
  padding: 10px 0;
  border-radius: 8px 8px 0 0;
  position: relative;
  .go-back-a {
    position: absolute;
    left: 3%;
    top: 50%;
    transform: translateY(-50%);
  }
}

.form-content {
  background-color: #ffffff;
}

.form-menu {
  font-size: 16px;
  color: #101010;
  font-weight: 400;
  /* text-align: left; */
}

.el-form-item {
  margin-bottom: 0;
}

.form-menu-active {
  font-size: 16px;
  color: #101010;
  font-weight: bold;
  text-align: left;
  position: relative;
}

.form-title-font {
  font-size: 16px;
  color: #101010;
  font-weight: bold;
  text-align: left;
}

.form-value-font {
  font-size: 14px;
  color: #101010;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
}

.form-menu-active::after {
  position: absolute;
  width: 50%;
  height: 6px;
  left: 0;
  right: 0;
  margin: auto;
}

.item-box-lg {
  padding: 20px 29px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  align-items: center;
}

.item-box-xs {
  padding: 10px 19px;
  border-bottom: 1px solid #eeeeee;
}
.go-back-btn {
  margin: 20px;
}

.videoBox {
  height: 100%;
  width: 100%;
}

.home-video {
  // object-fit: fill; /*这里是关键*/
  width: 100px;
  height: 100px;
}

.imgVideoList {
  display: flex;
}

.playIcon {
  position: absolute;
  font-size: 40px;
  color: #ffffff;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon-box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// zou走马灯样式
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
  display: flex;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.form-value-font {
  font-size: 14px;
  color: #101010;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
}
.el-cascader,
.el-input {
  width: 50%;
}

/* 去掉右侧上下箭头 */
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
